<template>
  <v-dialog
    v-if="venda.status != 2 && !loading"
    v-model="dialogEnviarCaixa"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-card-title dark class="text-center tocs_gray_1 text-body-1">
        Deseja Enviar para o caixa essa venda?
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between px-4 text-subtitle-2 mt-4">
          <span>Data limite de crédito:</span>
          <div>
            {{ data_limite_credito | dateFormat("dd/MM/yyyy") }}
          </div>
        </div>

        <div
          v-if="cliente.moeda_sigla === venda.moeda_sigla"
          class="d-flex justify-space-between px-4 text-subtitle-2 mt-4"
        >
          <span>Limite Cliente:</span>

          <div>
            {{ cliente.moeda_sigla }}
            <span v-if="cliente.moeda_sigla === 'G$'">
              {{ credito_cliente > 0 ? credito_cliente : 0 | guarani }}
            </span>
            <span v-else>{{
              credito_cliente > 0 ? credito_cliente : 0 | currency
            }}</span>
          </div>
        </div>

        <div class="d-flex align-center px-2 mb-n6">
          <v-spacer></v-spacer>

          <v-tooltip class="pr-2" v-if="gerarContrato" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="historicoCliente"
                icon
                v-bind="attrs"
                v-on="on"
                dark
                small
                fab
              >
                <v-icon :color="dadosAutorizado ? 'green' : 'red'"
                  >mdi-information-outline</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $tc(`global.${showMensagemTooltip}`) }}</span>
          </v-tooltip>
          <v-checkbox
            v-model="gerarContrato"
            :disabled="venda.tipo_venda === 'PISCINA'"
            :label="$tc('global.vendaComContrato')"
          ></v-checkbox>
        </div>

        <div
          v-if="disableAprazo && cliente.moeda_sigla === venda.moeda_sigla"
          class="text-center error--text mb-4 text-subtitle-2"
        >
          {{ $tc("global.valorLimiteUltrapassado") }}!
        </div>
        <div
          v-if="cliente.moeda_sigla !== venda.moeda_sigla"
          class="text-center error--text ma-4 text-subtitle-2"
        >
          {{ $tc("global.limiteClienteMoedaDiferenca") }}
        </div>
        <h3 class="text-subtitle-2 ml-4 my-4">
          Selecione uma forma de pagamento
        </h3>

        <div class="d-flex align-center pl-4 pr-4">
          <v-btn-toggle
            mandatory
            style="width: 50%"
            v-model="venda.condicao_pagamento"
          >
            <v-btn :disabled="disableAvista" block :value="1" height="40px">
              {{ $tc("global.aVista") }}
            </v-btn>

            <v-btn :disabled="disableAprazo" block :value="2" height="40px">
              {{ $tc("global.aPrazo") }}
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-checkbox
          class="d-flex align-center justify-space-between px-4 mb-n4"
          v-model="entregaInicial"
          v-if="venda.condicao_pagamento == 2"
          :label="$tc('global.entregaInicial')"
        ></v-checkbox>

        <v-row
          v-if="entregaInicial"
          class="d-flex align-center justify-space-between px-4"
        >
          <v-col>
            <DataField
              v-if="entregaInicial"
              :data_sync.sync="data_entrega_inicial"
              :blocked_days="[5, 6]"
              :data_min="data_hoje"
              :data_max="data_limite_credito"
              obrigatorio
              :label="$tc('global.data') + ' ' + $tc('global.entregaInicial')"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-if="entregaInicial"
              ref="entrega_inicial"
              v-model="entrega_inicial"
              :placeholder="
                $tc('global.valor') + ' ' + $tc('global.entregaInicial')
              "
              :rules="valorMaximoEntregaInicial"
              hide-details
              dense
              flat
              outlined
              v-currency="vCurrencyOptions"
            />
          </v-col>
        </v-row>

        <div class="d-flex align-center justify-space-between pa-4">
          <DataField
            v-if="venda.condicao_pagamento === 2"
            :data_sync.sync="data_vencimento"
            :blocked_days="[5, 6]"
            :data_min="data_hoje"
            :data_max="data_limite_credito"
            :label="$tc('global.data-vencimento')"
          />

          <v-text-field
            v-show="venda.condicao_pagamento === 2"
            v-model="numero_parcelas"
            hide-details
            dense
            flat
            outlined
            append-outer-icon="mdi-plus"
            @click:append-outer="numero_parcelas++"
            prepend-icon="mdi-minus"
            @click:prepend="numero_parcelas > 1 ? numero_parcelas-- : ''"
            class="ml-1 input-center px-4"
            required
            style="max-width: 25%"
          >
          </v-text-field>
          <v-btn
            v-if="venda.condicao_pagamento === 2"
            @click="openGerarParcelas"
            small
            class="white--text"
            color="button_2"
            :disabled="notDataVencimento || parcelas.length > 0"
          >
            <v-icon left> mdi-numeric-1-box-multiple-outline </v-icon>
            Gerar Parcelas
          </v-btn>
        </div>

        <TabelaParcelarVendas
          v-if="dialogParcelarVendas && venda.condicao_pagamento === 2"
          :dialogParcelarVendas.sync="dialogParcelarVendas"
          :venda="venda"
          :entrega_inicial.sync="entrega_inicial"
          :data_entrega_inicial.sync="data_entrega_inicial"
          :parcela_entrega_inicial.sync="parcela_entrega_inicial"
          :data_vencimento.sync="data_vencimento"
          :parcelas_venda.sync="parcelas"
          :numero_parcelas.sync="numero_parcelas"
          :intervalo_parcelas.sync="intervalo"
        />

        <ObservacoesGerais
          class="pa-4"
          :origem="'VENDAS'"
          :origem_id="venda.id"
        />

        <DialogTicket
          v-if="dialogTicket"
          :dialogTicket.sync="dialogTicket"
          :venda_id="venda_id"
          :possui_contrato="this.gerarContrato"
          @fechar-dialog="fecharDialog"
        />

        <DialogProdutosFaltando
          v-if="dialogProdutosFaltando"
          :dialogProdutosFaltando.sync="dialogProdutosFaltando"
          :produtos="produtos_faltando"
        />

        <DialogHistorticoCliente
          v-if="dialogHistoricoCliente"
          :dialogHistoricoCliente.sync="dialogHistoricoCliente"
          :cliente_id="cliente.id"
          :tipo_venda="'PISCINA'"
        />

        <DialogContrato
          v-if="dialogContrato"
          :dialogContrato.sync="dialogContrato"
          :venda="venda"
          :venda_items="venda_items"
          :vendaInProcess="true"
          :contrato.sync="contrato"
          @close="enviarCaixaContrato"
        />

        <v-card-text class="text-body-2 py-3">
          Após a venda ser finalizada não será possivel edita-la!
        </v-card-text>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          small
          class=""
          color="tocs_gray_2"
          text
          depressed
          @click="closeDialog"
        >
          Não Enviar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!gerarContrato"
          small
          depressed
          color="button_1"
          class="white--text"
          :disabled="envioValidado"
          @click="enviarCaixa"
        >
          <v-icon left small class="mx-1">mdi-cash-register</v-icon>
          {{ $tc("global.enviarCaixa") }}
        </v-btn>
        <v-btn
          v-if="gerarContrato"
          small
          depressed
          color="button_1"
          class="white--text"
          :disabled="envioValidado"
          @click="abrirContrato"
        >
          <v-icon left small class="mx-1">mdi-file-sign</v-icon>
          {{ $tc("global.contrato") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { enviarCaixa } from "@/api/vendas/vendas.js";
import { format } from "date-fns";
import { fetchVenda, getIntervalosParcelas } from "@/api/vendas/vendas.js";
import { fetchVendasItems } from "@/api/vendas/vendas_items.js";
import { fetchClienteReferencias } from "@/api/clientes/clientes_refencias.js";
import {
  fetchClienteCredito,
  fetchClienteDataLimiteCredito,
  // getCliente,
  clienteInfosVenda,
} from "@/api/clientes/clientes.js";
import { mapState } from "vuex";

export default {
  name: "DialogEnviarCaixa",

  props: {
    dialogEnviarCaixa: {
      type: Boolean,
      default: false,
    },
    venda_id: {
      type: Number,
      required: true,
      // default: 0,
    },
  },

  components: {
    TabelaParcelarVendas: () => import("./TabelaParcelarVendas.vue"),
    DialogTicket: () => import("./DialogTicket.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    DialogProdutosFaltando: () => import("./DialogProdutosFaltando.vue"),
    DialogHistorticoCliente: () => import("./DialogHistoricoCliente.vue"),
    DialogContrato: () =>
      import("@/views/dashboard/caixas-pdv/components/DialogContrato.vue"),
    ObservacoesGerais: () =>
      import(
        "@/views/dashboard/observacoes-gerais/components/ObservacoesGerais.vue"
      ),
  },

  data() {
    return {
      showTooltipInformacao: false,
      dialogContrato: false,
      loading: true,
      numero_parcelas: 1,
      entrega_inicial: null,
      parcelas: [],
      gerarContrato: false,
      entregaInicial: false,
      intervalo: 0,
      dialogParcelarVendas: false,
      data_vencimento: format(new Date(), "yyyy-MM-dd"),
      data_entrega_inicial: format(new Date(), "yyyy-MM-dd"),
      parcela_entrega_inicial: null,
      venda: {
        obs: "",
      },
      venda_items: [],
      data_hoje: format(new Date(), "yyyy-MM-dd"),
      credito_cliente: 0,
      data_limite_credito: "",
      cliente: {},
      dialogTicket: false,
      dialogProdutosFaltando: false,
      produtos_faltando: [],
      dadosAutorizado: true,
      dialogHistoricoCliente: false,
      contrato: null,
      showMensagemTooltip: "clienteNaoAutorizadoVendaPiscina",
      valorMaximoEntregaInicial: [
        () => this.entrega_inicial <= this.venda.total,
      ],
      dialogObservacao: false,
    };
  },

  watch: {
    "venda.condicao_pagamento": function (newVal, oldVal) {
      if (this.venda.condicao_pagamento == 1) {
        this.gerarContrato = false;
        this.entregaInicial = false;
      }
    },

    numero_parcelas() {
      if (this.numero_parcelas <= 0) {
        this.numero_parcelas = 1;
      }
    },

    entrega_inicial() {
      this.entrega_inicial = this.$ci.parse(
        this.entrega_inicial,
        this.vCurrencyOptions
      );
    },

    entregaInicial() {
      if (!this.entregaInicial) {
        this.entrega_inicial = null;
        this.data_entrega_inicial = null;
      }
    },

    async gerarContrato() {
      if (this.gerarContrato) {
        this.venda.condicao_pagamento = 2;

        const referenciaClienteDados = await fetchClienteReferencias(
          this.cliente.id
        );
        if (
          (this.cliente.celular || this.cliente.telefone) &&
          (this.cliente.doc_ci || this.cliente.doc_ruc) &&
          this.cliente.credito &&
          Number(this.cliente.credito) > 0 &&
          this.cliente.endereco_principal &&
          this.cliente.nome &&
          this.cliente.local_trabalho &&
          referenciaClienteDados[0]?.nome_conjugue &&
          referenciaClienteDados[0]?.num_cedula_conjugue &&
          referenciaClienteDados[0]?.tel_conjugue &&
          referenciaClienteDados[0]?.end_conjugue &&
          referenciaClienteDados[0]?.end_trabalho
        ) {
          this.dadosAutorizado = true;
          this.showMensagemTooltip = "clienteAutorizadoVendaPiscina";
        } else {
          this.dadosAutorizado = false;
          this.showMensagemTooltip = "clienteNaoAutorizadoVendaPiscina";
        }
      } else {
        this.dadosAutorizado = true;
      }
    },
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
    notDataVencimento() {
      let result = false;

      if (
        this.data_vencimento === null ||
        this.data_vencimento === undefined ||
        this.data_vencimento === ""
      ) {
        result = true;
      }
      return result;
    },
    envioValidado() {
      let result = false;
      if (this.disableAprazo && this.disableAvista) {
        result = true;
      }
      if (this.venda.condicao_pagamento === 2) {
        if (
          this.numero_parcelas <= 0 ||
          this.notDataVencimento ||
          this.parcelas.length <= 0
        ) {
          result = true;
        }
      }
      if (!this.dadosAutorizado) {
        result = true;
      }
      if (this.entregaInicial) {
        if (
          this.entrega_inicial <= 0 ||
          this.data_entrega_inicial === null ||
          this.data_entrega_inicial === undefined ||
          this.data_entrega_inicial === "" ||
          this.entrega_inicial > this.venda.total
        ) {
          result = true;
        }
      }
      return result;
    },

    disableAprazo() {
      let result = false;
      if (Number(this.credito_cliente) < Number(this.venda.total)) {
        result = true;
      }
      return result;
    },

    disableAvista() {
      let result = false;
      if (this.gerarContrato) {
        result = true;
      }
      if (Number(this.venda.tipo_venda == "PISCINA")) {
        result = true;
      }
      return result;
    },
  },

  methods: {
    abrirObservacao() {
      this.dialogObservacao = true;
    },
    abrirContrato() {
      this.venda.parcelas = this.parcelas;
      this.venda.entrega_inicial = this.entrega_inicial;
      this.venda.data_entrega_inicial = this.data_entrega_inicial;
      this.dialogContrato = true;
    },
    enviarCaixaContrato() {
      this.enviarCaixa();
    },
    historicoCliente() {
      this.dialogHistoricoCliente = true;
    },
    fetchVarIntervalo() {
      getIntervalosParcelas().then((response) => {
        let variavel_intervalo = response;
        this.intervalo = Number(variavel_intervalo);
        // this.data_intervalo =  response;
      });
    },
    preencheVenda() {
      this.loadingVenda = true;
      let venda = {};
      venda.status = 2;

      venda.cliente_id = this.venda.cliente_id;
      venda.cliente_nome = this.venda.cliente_nome;
      venda.cliente_endereco = this.venda.cliente_endereco;
      venda.cliente_estado = this.venda.cliente_estado;
      venda.cliente_pais = this.venda.cliente_pais;
      venda.cliente_documento = this.venda.cliente_documento;
      venda.cliente_cidade = this.venda.cliente_cidade;

      venda.moeda_id = this.venda.moeda_id;
      venda.lista_preco_id = this.venda.lista_preco_id;
      venda.desconto = this.venda.desconto;
      venda.user_id = this.venda.user_id;
      venda.valor = this.venda.valor;
      venda.total = this.venda.total;
      venda.obs = this.venda.obs;
      venda.condicao_pagamento = this.venda.condicao_pagamento;
      venda.data_vencimento = this.data_vencimento;
      venda.deposito_id = this.venda.deposito_id;
      venda.venda_items = this.venda_items;
      venda.possui_contrato = this.gerarContrato;
      if (this.gerarContrato) venda.contrato = this.contrato;
      if (this.venda.condicao_pagamento === 2) {
        venda.numero_parcelas = this.numero_parcelas;
        venda.parcelas = this.parcelas;
      }
      if (this.parcela_entrega_inicial) {
        venda.parcelas.unshift(this.parcela_entrega_inicial);
        if (this.gerarContrato) {
          venda.contrato.entrega_inicial = this.entrega_inicial;
          venda.contrato.data_entrega_inicial = this.data_entrega_inicial;
        }
      }
      return venda;
    },
    openGerarParcelas() {
      this.dialogParcelarVendas = true;
    },
    enviarCaixa() {
      if (!this.venda_items.length) {
        this.$toast.error(
          "Adicione items a lista de venda antes de finalizar a venda!"
        );
      }
      if (this.venda.condicao_pagamento) {
        if (this.venda.condicao_pagamento === 1) {
          this.data_vencimento = null;
          this.numero_parcelas = null;
          this.validaCondicaoPgto = true;
        }
        if (this.venda.condicao_pagamento === 2 && !this.data_vencimento) {
          this.$toast.error(
            "Selecione uma Data de Vencimento para finalizar a venda!"
          );
          this.dialogEnviarCaixa = false;
          this.dialogConfiguracao = true;
        } else {
          this.validaCondicaoPgto = true;
        }
      }
      if (this.validaCondicaoPgto) {
        let venda = this.preencheVenda();
        enviarCaixa(this.venda.id, venda)
          .then((response) => {
            if (response.status === 200) {
              this.loadingVenda = false;
              this.dialogTicket = true;
            }
          })
          .catch((error) => {
            this.produtos_faltando = error.response.data.qtde_items_somados;
            this.dialogProdutosFaltando = true;
          })
          .finally(() => {
            this.loadingVenda = false;
          });
      }
    },

    fecharDialog() {
      this.$emit("update:dialogNovaVenda", false);
      this.$emit("enviar-caixa");
    },

    async getClienteCredito() {
      this.loading = true;
      await fetchClienteCredito(this.venda.cliente_id)
        .then((response) => {
          this.credito_cliente = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      await fetchClienteDataLimiteCredito(this.venda.cliente_id)
        .then((response) => {
          this.data_limite_credito = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getCliente() {
      this.loading = true;
      await clienteInfosVenda(this.venda.cliente_id)
        .then((response) => {
          this.cliente = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    closeDialog() {
      this.$emit("update:dialogEnviarCaixa", false);
      this.$emit("fetch-venda-itens");
    },
  },
  async mounted() {
    this.fetchVarIntervalo();
    this.venda = await fetchVenda(this.venda_id);
    this.venda_items = await fetchVendasItems(this.venda_id);
    await this.getCliente();
    await this.getClienteCredito();
    if (
      (this.venda.cliente_agente_retentor === true ||
        this.venda.tipo_venda === "PISCINA") &&
      Number(this.credito_cliente) >= Number(this.venda.total)
    ) {
      this.venda.condicao_pagamento = 2;
    } else {
      this.venda.condicao_pagamento = 1;
    }
    if (this.venda.tipo_venda === "PISCINA") {
      this.gerarContrato = true;
    }
    this.loading = false;
  },
};
</script>

<style></style>
